<template>
  <b-card
    class="card-app-design rooms-container"
    title="Inventory"
  >
    <b-row>
      <b-col sm="12">
        <div class="custom-search d-md-flex justify-content-end mb-3">
          <b-form-group>
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex justify-content-center"
            >
              <span>
                <b-button
                  variant="outline-primary"
                  @click="handleOpenViewStockoutModal(props.row)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          v-model="openRegisterInventoryDetailsModal"
          title="Stockout Details"
          size="lg"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Item:</span><span class="text-info ml-1">{{ stockoutFormData.item_name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Available Quantity:</span><span class="text-info ml-1">{{ stockoutFormData.quantity }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Unit:</span><span class="text-info ml-1">{{ stockoutFormData.itemUnit }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Cost Price(Per Unit):</span><span class="text-info ml-1">{{ stockoutFormData.stockout_date }}</span></p>
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge,
  BFormSelect, BModal,
  BPagination, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BModal,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      log: [],
      pageLength: 3,
      openRegisterStockoutModal: false,
      openRegisterInventoryDetailsModal: false,
      openManageLivestockModal: false,
      selectedStatus: null,
      stockoutItem: null,
      livestockType: null,
      itemUnit: null,
      stockinChannel: null,
      dir: false,
      stockoutFormData: {},
      pages: ['3', '5', '10'],
      columns: [
        {
          label: 'Name',
          field: 'item_name',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Unit',
          field: 'unit',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.handleGetStockinData()
  },
  methods: {
    handleOpenViewStockoutModal(item) {
      this.openRegisterInventoryDetailsModal = true
      this.stockoutFormData = item
      this.$router.push(`/inventory/details?id=${item.item_id}`)
    },
    async handleGetStockinData() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('inventory')
          .select(`
            *
          `).order('item_name', { ascending: true })
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else if (response.length > 0) {
          console.log('::debug produce response:', response)
          this.rows = []
          this.rows = response
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
            alert('form submitted!')
        }
      })
    },
  },
}
</script>
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
